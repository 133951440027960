// icon made by Vaadin from Flaticon

import * as React from "react";

function SvgNext(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M2 1v14l10-7zM12 1h2v14h-2V1z" />
    </svg>
  );
}

export default SvgNext;
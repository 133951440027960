import React, {Component} from 'react';
import NextButton from './svg/SvgNext';

interface NextProps {
    disabled: boolean,  // true when next button is disabled
    onNext(): void      // called when next button is clicked
}

class Next extends Component<NextProps, {}> {

    render() {
        if (this.props.disabled) {
            return (<NextButton id="next" className="disabled" />);
        } else {
            return (<NextButton id="next" onClick={this.props.onNext} />);
        }
    }

}

export default Next;
import React, {Component} from 'react';
import {Project} from './project';
import './Description.css';
import {SwitchTransition, CSSTransition} from 'react-transition-group';

interface DescriptionProps {
    project: Project,   // current project
    index: number       // index of current project; to identify unique project for animation
}

class Description extends Component<DescriptionProps, {}> {

    // generate subtitle according to type of project
    generateSubtitle = (type: string): string => {
        let start = "";
        switch(type) {
            case "personal":
                start += "Personal Project";
                break;
            case "course":
                start += this.props.project.course + " @ " + this.props.project.school;
                break;
            case "internship":
                start += this.props.project.role + " @ " + this.props.project.company;
                break;
            default:
                start += "Project";
        }
        return start + " / " + this.props.project.date;
    }

    render() {
        return (
            <div id="description">
                <SwitchTransition>
                    <CSSTransition
                        key={this.props.index}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames="pan-right"
                    >
                        <h2 id="title">{this.props.project.title}</h2>
                    </CSSTransition>
                </SwitchTransition>

                <SwitchTransition>
                    <CSSTransition
                        key={this.props.index}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames="pan-right"
                    >
                        <p id="subtitle">{this.generateSubtitle(this.props.project.type)}</p>
                    </CSSTransition>
                </SwitchTransition>

                <SwitchTransition>
                    <CSSTransition
                        key={this.props.index}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames="pan-right"
                    >
                        <p id="content">{this.props.project.description}</p>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        );
    }

}

export default Description;
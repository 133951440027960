import React, {Component} from 'react';
import Cover from './Cover';
import Description from './Description';
import {Project} from './project';
import './Album.css';

interface AlbumProps {
    project: Project    // current project
    index: number       // index of current project; to identify unique project for animation
    // TODO: better way to identify unique project? (eg. implement hash function for Project)
}

class Album extends Component<AlbumProps, {}> {

    render() {
        return (
            <div id="album">
                <Cover image={this.props.project.image} index={this.props.index} />
                <Description project={this.props.project} index={this.props.index} />
            </div>
        );
    }

}

export default Album;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Project} from './project';

// TODO: move to database
const PROJECTS: Project[] = [
    {title: "A Better Password Today", description: "web app that generates safe passwords based on an algorithm written in JavaScript", type: "personal", date: 2017, image: "1.png", link: "https://betterpassword.sangwon.kim"},
    {title: "A Better URL Today", description: "web app that shortens URLs using SQLite", type: "personal", date: 2017, image: "2.png", link: "https://betterurl.sangwon.kim"},
    {title: "A Cup of Coffee Today", description: "web app that recommends nearby cafes based on the user's auto-detected location using geolocation, Yelp API, and Google Maps API", type: "personal", date: 2017, image: "3.png", link: "https://coffee.sangwon.kim"},
    {title: "Film Industry Data Analysis", description: "team project on data analysis of the growth of the film industry using R", type: "course", course: "INFO 201", school: "University of Washington", date: 2019, image: "4.png", link: "https://info201-final-project.shinyapps.io/film-industry-data-analysis/"},
    {title: "Resume Action Verbs Thesaurus", description: "web app that suggests more effective and powerful words for resume", type: "personal", date: 2020, image: "5.png", link: "https://actionverbs.sangwon.kim/"},
    {title: "The Pale Blue Dot", description: "web ray tracer built in JavaScript accompanied by a step-by-step tutorial for beginners", type: "internship", role: "Research Intern", company: "Seoul National University", date: 2020, image: "6.png", link: "https://raytracing.sangwon.kim/"},
    {title: "UW Campus Paths", description: "web app that finds the shortest path between two buildings on UW campus, built in TypeScript, React / Java, Spark", type: "course", course: "CSE 331", school: "University of Washington", date: 2021, image: "7.png"}
];

ReactDOM.render(
    <React.StrictMode>
        <App projects={PROJECTS} />
    </React.StrictMode>,
    document.getElementById('root')
);
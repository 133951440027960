// icon made by Vaadin from Flaticon

import * as React from "react";

function SvgPrev(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M14 15V1L4 8zM2 1h2v14H2V1z" />
    </svg>
  );
}

export default SvgPrev;
// icon made by Pixel perfect from Flaticon

import * as React from "react";

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M339.392 258.624L512 367.744V144.896zM0 144.896v222.848l172.608-109.12zM480 80H32C16.032 80 3.36 91.904.96 107.232L256 275.264l255.04-168.032C508.64 91.904 495.968 80 480 80zM310.08 277.952l-45.28 29.824a15.983 15.983 0 01-8.8 2.624c-3.072 0-6.112-.864-8.8-2.624l-45.28-29.856L1.024 404.992C3.488 420.192 16.096 432 32 432h448c15.904 0 28.512-11.808 30.976-27.008L310.08 277.952z" />
    </svg>
  );
}

export default SvgEmail;

// import * as React from "react";

// function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
//       <path d="M480 80H32C14.336 80 0 94.336 0 112v18.208l256 108.416 256-108.448V112c0-17.664-14.336-32-32-32z" />
//       <path d="M262.24 270.72c-1.984.864-4.128 1.28-6.24 1.28s-4.256-.416-6.24-1.28L0 164.96V400c0 17.664 14.336 32 32 32h448c17.664 0 32-14.336 32-32V164.96L262.24 270.72z" />
//     </svg>
//   );
// }

// export default SvgEmail;
import React, {Component} from 'react';
import {Project} from './project';
import Album from './Album';
import Player from './Player';
import GithubIcon from './svg/SvgGithub';
import EmailIcon from './svg/SvgEmail';
import LinkedinIcon from './svg/SvgLinkedin';

interface AppProps {
    projects: Project[] // list of projects
}

interface AppState {
    index: number       // index of current project
}

class App extends Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            index: this.props.projects.length - 1
        }
    }

    updateProject = (index: number) => {
        this.setState({
            index: index
        });
    }

    render() {
        let project: Project = this.props.projects[this.state.index];
        
        return (
            <div id="app">
                <div id="header">
                    <h1 id="header-title">SANGWON'S PLAYLIST</h1>
                    <div id="icons">
                        {/* github */}
                        <a href="https://github.com/sangwo" target="_blank" rel="noreferrer" className="icon-wrapper"><GithubIcon className="icon" /></a>
                        {/* email */}
                        <a href="mailto:swkim23@cs.washington.edu" className="icon-wrapper"><EmailIcon className="icon" /></a>
                        {/* linkedin */}
                        <a href="https://www.linkedin.com/in/swkim23" target="_blank" rel="noreferrer" className="icon-wrapper"><LinkedinIcon className="icon" /></a>
                    </div>
                </div>

                <Album project={project} index={this.state.index} /> {/* TODO: more accurate props? */}
                <Player project={project} index={this.state.index} onUpdate={this.updateProject} maxIndex={this.props.projects.length - 1} />
            </div>
        );
    }

}

export default App;
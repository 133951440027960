// icon made by Vaadin from Flaticon

import * as React from "react";

function SvgPlay(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M2 1v14l12-7z" />
    </svg>
  );
}

export default SvgPlay;
import React, {Component} from 'react';
import Prev from './Prev';
import Next from './Next';
import Play from './Play';
import {Project} from './project';
import './Player.css';

interface PlayerProps {
    project: Project,               // current project
    index: number,                  // index of current project
    onUpdate(index: number): void,  // called when project is updated
    maxIndex: number                // index of the last project
}

class Player extends Component<PlayerProps, {}> {

    // given that newIndex is valid,
    // update current project to previous (more recent) project if it exists
    onPrev = () => {
        let newIndex = this.props.index + 1;
        // if (newIndex > this.props.maxIndex) {
        //     return;
        // }
        this.props.onUpdate(newIndex);
    }

    // given that newIndex is valid,
    // update current project to next (older) project if it exists
    onNext = () => {
        let newIndex = this.props.index - 1;
        // if (newIndex < 0) {
        //     return;
        // }
        this.props.onUpdate(newIndex);
    }

    render() {
        // TODO: better way to add disabled class name?
        return (
            <div id="player">
                <Prev disabled={this.props.index + 1 > this.props.maxIndex} onPrev={this.onPrev} />
                <Play link={this.props.project.link} />
                <Next disabled={this.props.index - 1 < 0} onNext={this.onNext} />
            </div>
        );
    }

}

export default Player;
import React, {Component} from 'react';
import PrevButton from './svg/SvgPrev';

interface PrevProps {
    disabled: boolean,  // true when prev button is disabled
    onPrev(): void      // called when prev button is clicked
}

class Prev extends Component<PrevProps, {}> {
    
    render() {
        if (this.props.disabled) {
            return (<PrevButton id="prev" className="disabled" />);
        } else {
            return (<PrevButton id="prev" onClick={this.props.onPrev} />);
        }
    }

}

export default Prev;
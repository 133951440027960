import React, {Component} from 'react';
import PlayButton from './svg/SvgPlay';

interface PlayProps {
    link: string | undefined // link of current project
}

class Play extends Component<PlayProps, {}> {
    
    render() {
        if (this.props.link) {
            return (<a href={this.props.link} target="_blank" rel="noreferrer"><PlayButton id="play" /></a>
            );
        } else {
            return (<PlayButton id="play" className="disabled" />);
        }
    }

}

export default Play;
import React, {Component} from 'react';
import './Cover.css'
import {SwitchTransition, CSSTransition} from 'react-transition-group';

interface CoverProps {
    image: string,  // image of current project
    index: number   // index of current project; to identify unique project for animation
}

class Cover extends Component<CoverProps, {}> {

    render() {
        return (
            <div id="cover">
                <SwitchTransition>
                    <CSSTransition
                        key={this.props.index}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames="pan-right"
                    >
                        <img id="coverImage" src={"covers/" + this.props.image} alt="coverImage" />
                    </CSSTransition>
                </SwitchTransition>

                <SwitchTransition>
                    <CSSTransition
                        key={this.props.index}
                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                        classNames="pan-right"
                    >
                        <img id="vinylImage" src="vinyl.png" alt="vinylImage" />
                    </CSSTransition>
                </SwitchTransition>
            </div>
        );
    }

}

export default Cover;